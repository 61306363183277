<script setup lang="ts">
import { Authenticator } from '@aws-amplify/ui-vue'
import NotificationsSlideover from '~/components/NotificationsSlideover.vue';
import NotificationBell from '~/components/NotificationBell.vue';
import type { QuickSightDashboard } from '~/types';

const route = useRoute();
const auth = useAuthStore();
const notificationStore = useNotificationStore();
const { isNotificationsSlideoverOpen } = useDashboard()
const config = useRuntimeConfig();

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - Workforce Development Institute` : 'Workforce Development Institute'
  }
})

callOnce(() => {
  auth.init(); 
  // TODO - Revisit in PWDI-390
//   setInterval(() => {
//     console.log("Token expires in: ", auth.exp - Date.now() / 1000);
//   if(auth.exp <= Date.now() / 1000) {
//     auth.signOut();
//   }
//   else if(auth.exp - Date.now() / 1000 < 60 * 5) {
//     toast.add({title: 'Your session will expire in 5 minutes.',
//       actions: [{label: 'Stay logged in', click: () => {
//         auth.refresh();
//       }}]
//     });
//   }
// }, 1000 * 60 * 5);
})

const authFormfields = {
  setupTotp: {
    QR: {
      totpIssuer: config.public.environment === 'prod' ? 'WDIPortal' : `WDIPortal-${config.public.environment}`,
    },
  },
}
const dashboardLinks = ref([]);

async function fetchAvailableDashboards() {
  dashboardLinks.value = [];
  if (auth.isAuthenticated) {
    const { data: availableDashboards } = await useApi<QuickSightDashboard[]>(`/v1/quicksightdashboards`);
    availableDashboards.value.forEach((dashboard: QuickSightDashboard) => {
      const item = {
        id: dashboard.reportId,
        label: dashboard.displayName,
        to: `/report/${dashboard.reportId}/`,
      };
      dashboardLinks.value.push(item);
    });
    links[links.findIndex(obj => obj.id=="reports")].children = dashboardLinks.value;
    visibleLinks = getVisibleLinks();
    sideBarKey.value += 1;
  }
}

const sideBarKey = ref(0);
const links = [{
  id: 'home',
  label: 'Dashboard',
  icon: 'i-heroicons-home',
  to: '/',
  tooltip: {
    text: 'Dashboard',
    shortcuts: ['G', 'H']
  }, 
  isVisible: true
}, {
  id: 'cases',
  label: 'Cases',
  icon: 'i-heroicons-queue-list', 
  isVisible: true,
  children: [{
    label: 'New Case',
    to: '/new-case',
    exact: true,
    tooltip: {
      text: 'New Case',
      shortcuts: ['G', 'N']
    }
  }, {
    label: 'Search Case',
    to: '/search',
    tooltip: {
      text: 'Search Case',
      shortcuts: ['G', 'S']
    }
  }]
},
{
  id: 'childcareproviders',
  label: 'Child Care Providers',
  icon: 'i-heroicons-building-office-2',
  to: '/providerlist',
  tooltip: {
    text: 'Child Care Providers',
    shortcuts: ['C', 'P']
  }, 
  isVisible: true
},
{
  id: 'reports',
  label: 'Reports',
  icon: 'i-heroicons-chart-bar-square',
  isVisible: true
},
{
  id: 'childcaresubs',
  label: 'CSP Admin',
  icon: 'i-heroicons-face-smile',
  defaultOpen: route.path.startsWith('/csp-admin'),
  isVisible: await auth.getIsCspAdmin(),
  children: [{
    id: 'assignmentattributes',
    label: 'Case Assignments',
    icon: 'i-heroicons-numbered-list',
    to: '/csp-admin/case-assignments',
    tooltip: {
      text: 'Case Assignments'
    }
  }],
  tooltip: {
    text: 'Child Care Subsidy Program Admins'
  }
},
{
  id: 'admin',
  label: 'System Admin',
  icon: 'i-heroicons-cog-8-tooth',
  defaultOpen: route.path.startsWith('/admin'),
  isVisible: await auth.getIsSystemAdmin(),
  children: [{
    id: 'users',
    label: 'Users',
    to: '/admin/users'
  }]
}]

const groups = [{
  key: 'links',
  label: 'Go to',
  commands: links.map(link => ({ ...link, shortcuts: link.tooltip?.shortcuts }))
}]

let visibleLinks = getVisibleLinks();

function getVisibleLinks(){
  return links.filter(link => {
        return link.isVisible
      })
}
onMounted(() => {
  visibleLinks = getVisibleLinks();
  sideBarKey.value += 1;
  if (auth.isAuthenticated) {
    notificationStore.startPolling();
  }
})

</script>

<template>
  <Authenticator :login-mechanisms="['email']" :hide-sign-up="true" :form-fields="authFormfields">
    <template #header>
      <div class="p5 mt-10">
        <img src="/wdi-logo-rgb.png" alt="WDI Logo" class="w-1/2 m-auto mb-10">
      </div>
    </template>
    <UDashboardLayout v-if="auth.isAuthenticated">
      <UDashboardPanel :width="250" :resizable="{ min: 200, max: 300 }" collapsible>
        <UDashboardNavbar class="!border-transparent" :ui="{ left: 'flex-1' }">
          <template #left>
            <img v-if="$colorMode.value == 'dark'" src="/wdi-logo.png" alt="WDI Logo" class="w-3/4 m-auto">
            <img v-else src="/wdi-logo-rgb.png" alt="WDI Logo" class="w-3/4 m-auto">
          </template>
          <template #right>
            <UTooltip text="Notifications" :shortcuts="['N']">
              <UButton color="gray" variant="ghost" square @click="isNotificationsSlideoverOpen = true">
                <NotificationBell />
              </UButton>
            </UTooltip>
          </template>
        </UDashboardNavbar>

      <UDashboardSidebar v-if="auth.isAuthenticated">
        <template #header>
          <div class="flex-1 mt-3" />
          <UDashboardSearchButton v-if="false" />
        </template>

        <!-- <UDivider class="sticky bottom-0" /> -->
        <UDashboardSidebarLinks 
          :key="sideBarKey" 
          :links="visibleLinks" 
          @click="(event) => {
              if ((event.target as HTMLSpanElement).textContent?.trim().toLowerCase() === 'reports') {
                fetchAvailableDashboards();
              }
            }" />
          <div class="flex-1" />
          <TeamsDropdown />

          <template #footer>
            <!-- ~/components/UserDropdown.vue -->
            <UserDropdown />
          </template>
        </UDashboardSidebar>
      </UDashboardPanel>
        <slot />

        <!-- ~/components/HelpSlideover.vue -->
        <HelpSlideover />
        <!-- ~/components/NotificationsSlideover.vue -->
        <NotificationsSlideover  />

        <ClientOnly>
          <LazyUDashboardSearch :groups="groups" />
        </ClientOnly>
    </UDashboardLayout>
    <UProgress v-else animation="carousel" />
  </Authenticator>
</template>
